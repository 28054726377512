// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

// export function convertToExcel(
//     jsonData: any[],
//     columnMapping: { key: string; header: string }[],
//     fileName: string
// ) {
//     try {
//         const mappedData = jsonData.map((row) => {
//             const newRow: any = {};
//             columnMapping.forEach((mapping) => {
//                 newRow[mapping.header] = row[mapping.key];
//             });
//             return newRow;
//         });

//         const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedData);
//         const workbook: XLSX.WorkBook = {
//             Sheets: { data: worksheet },
//             SheetNames: ['data'],
//         };
//         const excelBuffer: any = XLSX.write(workbook, {
//             bookType: 'xlsx',
//             type: 'array',
//         });
//         saveFile(excelBuffer, fileName + '.xlsx', 'application/octet-stream');
//     } catch (error) {
//         console.error(error);
//     }
// }

function rgbToHex(rgb: string): string {
    const result = rgb.match(/\d+/g);
    if (!result || result.length < 3) return 'FFFFFF'; // Default to white if conversion fails
    return (
        '#' +
        result
            .slice(0, 3)
            .map((x) => parseInt(x).toString(16).padStart(2, '0'))
            .join('')
    ).toUpperCase();
}

export function convertToExcel(
    jsonData: any[],
    columnMapping: { key: string; header: string }[], 
    fileName: string
) {
    try {
        // Dynamically get header styles
        const headerElement = document.querySelector('table thead th');
        const headerColor = headerElement
            ? rgbToHex(window.getComputedStyle(headerElement).backgroundColor)
            : '#0073CF'; // Default header background color
        const headerFontColor = headerElement
            ? rgbToHex(window.getComputedStyle(headerElement).color)
            : '#FFFFFF'; // Default header font color

        // Use headerColor for rows as well
        const rowColor = headerColor; // Row color matches header background
        const alternateRowColor = '#F0F8FF'; // Alternate row color

        // Map JSON data to match columns
        const mappedData = jsonData.map((row) => {
            const newRow: any = {};
            columnMapping.forEach((mapping) => {
                newRow[mapping.header] = row[mapping.key];
            });
            return newRow;
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedData);
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };

        // Define styles
        const headerStyle = {
            font: { bold: true, color: { rgb: headerFontColor.slice(1) } },
            fill: { fgColor: { rgb: headerColor.slice(1) } },
            alignment: { horizontal: 'center', vertical: 'center' },
        };

        const rowStyle = {
            font: { color: { rgb: '000000' } },
            fill: { fgColor: { rgb: rowColor.slice(1) } },
        };

        const alternateRowStyle = {
            font: { color: { rgb: '000000' } },
            fill: { fgColor: { rgb: alternateRowColor.slice(1) } },
        };

        const range = XLSX.utils.decode_range(worksheet['!ref']!);

        // Apply header styles
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const headerCell = XLSX.utils.encode_cell({ r: 0, c: C });
            if (worksheet[headerCell]) {
                worksheet[headerCell].s = headerStyle;
            }
        }

        // Calculate the maximum column width for each field (auto width)
        const colWidths: any[] = [];
        for (let C = range.s.c; C <= range.e.c; ++C) {
            let maxLength = columnMapping[C]?.header.length; // Initialize with header length
            for (let R = 0; R <= range.e.r; ++R) {
                const cell = XLSX.utils.encode_cell({ r: R, c: C });
                const value = worksheet[cell]?.v;
                if (value) {
                    maxLength = Math.max(maxLength, String(value).length);
                }
            }
            // Apply a maximum width limit to avoid overly large columns
            colWidths[C] = { wpx: Math.min(maxLength * 10, 200) }; // Adjust multiplier or max width limit as needed
        }

        // Apply row styles and align based on value type
        for (let R = 1; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell = XLSX.utils.encode_cell({ r: R, c: C });
                if (worksheet[cell]) {
                    const value = worksheet[cell].v;
                    const isNumber = typeof value === 'number';

                    // Default alignment
                    let alignment = {
                        horizontal: isNumber ? 'right' : 'left',
                        vertical: 'center',
                    };

                    // Check for status fields and apply colors accordingly
                    const key = columnMapping[C]?.key;
                    const field = jsonData[R - 1][key];
                    if (key === 'status') {
                        const statusElement = document.querySelector(`.status.${field}`);
                        const statusColor = statusElement
                            ? rgbToHex(window.getComputedStyle(statusElement).backgroundColor)
                            : '#D3D3D3'; // Default status color

                        // Apply white font color and center alignment for status field
                        worksheet[cell].s = {
                            ...(R % 2 === 0 ? rowStyle : alternateRowStyle),
                            font: { color: { rgb: 'FFFFFF' } }, // White font color
                            fill: { fgColor: { rgb: statusColor.slice(1) } },
                            alignment: { horizontal: 'center', vertical: 'center' }, // Center text
                        };
                    } else {
                        worksheet[cell].s = {
                            ...(R % 2 === 0 ? rowStyle : alternateRowStyle),
                            alignment: alignment,
                        };
                    }
                }
            }
        }

        // Set column widths to auto based on content
        worksheet['!cols'] = columnMapping.map((col, index) => ({
            wpx: colWidths[index]?.wpx || 80, // Apply calculated width or default width if undefined
        }));

        // Generate Excel file
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        saveFile(excelBuffer, fileName + '.xlsx', 'application/octet-stream');
    } catch (error) {
        console.error('Error during export:', error);
    }
}

export async function convertToPdf(
    jsonData: any[],
    columnMapping: { key: string; header: string }[],
    fileName: string
) {
    try {
        const mappedData = jsonData.map((row) => {
            const newRow: any = {};
            columnMapping.forEach((mapping) => {
                newRow[mapping?.header] = row[mapping.key] ?? '';
            });
            return newRow;
        });

        const tableWidth = columnMapping.reduce((acc, column) => {
            return acc + 50;
        }, 0);

        const pageWidthMM = 210;

        const scaleFactor = pageWidthMM / tableWidth;

        const element = document.getElementsByTagName('body');
        let primaryColor;
        if (element && element?.length) {
            const computedStyles = window.getComputedStyle(element[0]);
            if (computedStyles) {
                primaryColor =
                    computedStyles.getPropertyValue('--fuse-primary');
            }
        }

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            content: [
                { text: fileName, style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: columnMapping.map(() => 'auto'),
                        body: [
                            columnMapping.map((mapping) => {
                                return {
                                    text: mapping.header,
                                    style: 'tableHeader',
                                };
                            }),
                            ...mappedData.map((row) =>
                                columnMapping.map(
                                    (mapping) => row[mapping.header]
                                )
                            ),
                        ],
                    },
                    fontSize: 12 * scaleFactor,
                },
            ],
            styles: {
                header: {
                    fontSize: 18 * scaleFactor,
                    bold: true,
                    margin: [0, 0, 0, 10],
                    color: primaryColor || '#4f46e5',
                },
                tableHeader: { bold: true, color: primaryColor || '#4f46e5' },
            },
        };

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        await pdfMake.createPdf(docDefinition).getBuffer((pdfBuffer) => {
            saveFile(pdfBuffer, fileName + '.pdf', 'application/pdf');
        });
    } catch (error) {
        console.error(error);
    }
}

function saveFile(buffer: any, fileName: string, fileType: string) {
    try {
        const data: Blob = new Blob([buffer], { type: fileType });
        const downloadLink: HTMLAnchorElement = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(data);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } catch (error) {
        console.error(error);
    }
}

export function generateUniqueId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

export function getDataByFieldsString(fieldString: string, data: any) {
    const fields = fieldString?.split('.');
    let value = data;
    for (let field of fields) {
        value = value?.[field];
    }
    return value;
}

export function file_to_key(file) {
    let key = "vortex/";
    if (file.tenant_id) key += file.tenant_id;
    if (file.category) key = `${key}/${file.category}`;
    if (file.sub_category) key = `${key}/${file.sub_category}`;
    key = `${key}/${file.key}`;
    return key;
}