<!-- Messages toggle -->
<button [matTooltip]="'Messages'" mat-icon-button (click)="openPanel()" #messagesOrigin aria-label="Open messages">
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:inbox'"></mat-icon>
</button>

<!-- Messages panel -->
<ng-template #messagesPanel>
    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">
        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()" aria-label="Close messages">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Messages</div>
            <div class="ml-auto">
                <button class="dark:text-white" mat-icon-button [disabled]="messages.length === 0" [matTooltip]="'Remove all messages'"
                    (click)="removeAllMessages()" aria-label="Remove all messages">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                </button>
                <button class="dark:text-white" mat-icon-button [disabled]="unreadCount === 0"
                    [matTooltip]="'Mark all as read'" (click)="markAllAsRead()" aria-label="Mark all as read">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:envelope-open'"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <ng-container *ngFor="let message of messages; trackBy: trackById">
                <div class="flex group dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'bg-gray-200': !message.read}">
                    <!-- Message with a link -->
                    <ng-container *ngIf="message.link">
                        <!-- Normal links -->
                        <ng-container *ngIf="!message.useRouter">
                            <a class="flex flex-auto py-5 pl-6 cursor-pointer" [href]="message.link">
                                <ng-container
                                    *ngTemplateOutlet="messageContent; context: {$implicit: message}"></ng-container>
                            </a>
                        </ng-container>
                        <!-- Router links -->
                        <ng-container *ngIf="message.useRouter">
                            <a class="flex flex-auto py-5 pl-6 cursor-pointer" [routerLink]="message.link">
                                <ng-container
                                    *ngTemplateOutlet="messageContent; context: {$implicit: message}"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container>

                    <!-- Message without a link -->
                    <ng-container *ngIf="!message.link">
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container
                                *ngTemplateOutlet="messageContent; context: {$implicit: message}"></ng-container>
                        </div>
                    </ng-container>

                    <!-- Actions -->
                    <div class="relative flex flex-col my-5 mr-6 ml-2">
                        <!-- Indicator -->
                        <button class="w-6 h-6 min-h-6" mat-icon-button (click)="toggleRead(message)"
                            [matTooltip]="message.read ? 'Mark as unread' : 'Mark as read'"
                            aria-label="Toggle read status">
                            <span class="w-2 h-2 rounded-full"
                                [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': message.read, 'bg-primary': !message.read}"></span>
                        </button>
                        <!-- Remove -->
                        <button class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100" mat-icon-button
                            (click)="removeMessage(message)" [matTooltip]="'Remove'" aria-label="Remove message">
                            <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                        </button>
                    </div>
                </div>
            </ng-container>

            <!-- No messages -->
            <ng-container *ngIf="!messages.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'heroicons_outline:inbox'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No messages</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have messages, they
                        will
                        appear here.</div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

<!-- Message content template -->
<ng-template #messageContent let-message>
    <div class="flex cursor-pointer" (click)="redirectToChat(message)">
        <ng-container *ngIf="message.icon && !message.image">
            <div
                class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                <mat-icon class="icon-size-5" [svgIcon]="message.icon"></mat-icon>
            </div>
        </ng-container>
        <!-- Image -->
        <ng-container *ngIf="message.image; else noImage">
            <img loading="lazy" class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                [src]="message.image" [alt]="'Message image'">
        </ng-container>
        <ng-template #noImage>
            <div class="shrink-0 w-8 h-8 mr-4 rounded-full flex items-center justify-center bg-gray-300">
                {{ message?.name?.charAt(0) || 'U' }}
            </div>
        </ng-template>
        <!-- Title, description & time -->
        <div class="flex flex-col flex-auto text-sm">
            <div class="font-semibold line-clamp-1">
                {{ message.name || 'User' }}
            </div>
            <div class="line-clamp-2 w-52 overflow-hidden text-ellipsis" [innerHTML]="message.value"></div>
            <div class="mt-2 text-sm leading-none text-secondary">
                {{message.createdAt | date:'MMM dd, h:mm a'}}
            </div>
        </div>
    </div>
</ng-template>