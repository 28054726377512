export const VALUE_SET_CODE_CONSTANTS = {
    API_REGISTRY_BASE_URL: 'API_REGISTRY_BASE_URL',
    API_CATEGORIES: 'API_CATEGORIES',
    API_FILTER_TYPES: 'API_FILTER_TYPES',
    MENU_ICON: 'MENU_ICON',
    COUNTRIES: 'COUNTRIES',
    NOTES_LABELS: 'NOTES_LABELS',
    WIDGET_ICON: 'WIDGET_ICON',
    FORM_COMPONENTS: 'FORM_COMPONENTS',
    BUSINESS_TYPE: 'BUSINESS_TYPE',
    PREFERRED_MOP: 'PREFERRED_MOP',
    PAYMENT_TERMS: 'PAYMENT_TERMS',
    TAB_COMPONENT: 'TAB_COMPONENT',
    ACTION_TYPES: 'ACTION_TYPES',
    ADVANCED_FILTERS: 'ADVANCED_FILTERS',
    AUTH_TYPES: 'AUTH_TYPES',
    DASHBOARD_WIDGET_TYPES: 'DASHBOARD_WIDGET_TYPES'
};

export const VALUE_SET_DETAILS_CODE_CONSTANTS = {
    COMPONENT_TYPE_INPUT_TEXT: 'VSDCODE01',
    COMPONENT_TYPE_LABEL: 'VSDCODE02',
    COMPONENT_TYPE_INPUT_NUMBER: 'VSDCODE03',
    COMPONENT_TYPE_NUMBER: 'VSDCODE04',
    COMPONENT_TYPE_DATE: 'VSDCODE05',
    COMPONENT_TYPE_CALENDAR: 'VSDCODE06',
    COMPONENT_TYPE_CHECKBOX: 'VSDCODE07',
    COMPONENT_TYPE_DROPDOWN: 'VSDCODE08',
    COMPONENT_TYPE_STATUS: 'VSDCODE09',
    COMPONENT_TYPE_IDENTIFIER: 'VSDCODE12',
    COMPONENT_TYPE_MODULE_DATA_PICKER: 'VSDCODE19',
    COMPONENT_TYPE_CURRENCY: 'VSDCODE20',
    COMPONENT_TYPE_DYNAMIC_LIST: 'VSDCODE21',
    COMPONENT_TYPE_CODE: 'VSDCODE22',
    COMPONENT_TYPE_IMAGE: 'VSDCODE23',
    FILTER_TYPE_SINGLE: 'VSDCODE_005',
    FILTER_TYPE_MULTIPLE: 'VSDCODE_006',
    FILTER_TYPE_FROM_TO: 'VSDCODE_007',
    FILTER_TYPE_ASCENDING: 'VSDCODE_008',
    FILTER_TYPE_DESCENDING: 'VSDCODE_009',
    AI_MODULE_CATEGORY: 'AI',
    TASK_APPROVAL_STATUS: 'TASK_APPROVAL_STATUS',
    STATUS: 'STATUS'
};
