import { Injectable } from '@angular/core';
import * as Diff from 'diff';

@Injectable({
    providedIn: 'root',
})
export class JsonDiffService {
    constructor() {}

    generateDiff(fileName: string, json1: any, json2: any): string {
        const jsonStr1 = JSON.stringify(json1, null, 2);
        const jsonStr2 = JSON.stringify(json2, null, 2);

        // Use Diff library to create a unified diff string
        const diff = Diff.createPatch(fileName, jsonStr1, jsonStr2);
        return diff;
    }
}
