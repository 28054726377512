import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Renderer2,
    SimpleChanges,
    ViewChild,
    EventEmitter,
    Output,
    ViewChildren,
    QueryList,
    AfterViewInit,
    HostListener
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { COMPONENT_REFERENCE } from 'app/shared/constants/component-reference.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { VALUE_SET_CODE_CONSTANTS } from 'app/shared/constants/value-set.contants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { generateUniqueId } from 'app/shared/utilities/utilities';
import { ITab } from './dynamic-tabs-i';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject, takeUntil } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';
import { cloneDeep, isEqual } from 'lodash';
import { SharedModule } from 'app/shared/shared.module';
import { AuthService } from 'app/core/auth/auth.service';
import { DynamicFormRendererComponent } from '../forms/forms-renderer/forms-renderer.component';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { NgComponentOutlet } from '@angular/common';

@Component({
    selector: 'app-dynamic-tabs',
    templateUrl: './dynamic-tabs.component.html',
    styleUrl: './dynamic-tabs.component.scss',
    standalone: true,
    imports: [SharedModule]
})
export class DynamicTabsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input('data') data: any;
    @Input() eventFromChild: any;
    @Output() dataEvent: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('component_container') componentContainer: ElementRef<any>;
    @ViewChild('drawer') drawer: MatDrawer;
    // @ViewChild(DynamicFormRendererComponent) formRenderComponent: DynamicFormRendererComponent;
    @ViewChild(NgComponentOutlet, { static: false }) ngComponentOutlet: NgComponentOutlet;
    links = [];
    module_code: string;
    // currentComponent: any;
    tabsData: ITab[] = [];
    // isSideMenuEnabled: boolean = environment.isSideMenuEnabledForTabs;
    tabPosition;
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    selectedTab: string;
    tenantsId: string;
    rowData: any;
    userByTenantId: any;
    activeLink = this.links[0];
    hideTabs = false;
    tabProperties: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private userInfo;
    receivedData: string;
    is_menu: boolean = false;
    show_close: boolean = true;
    isOverflow: boolean = false;
    @ViewChildren('sentenceRef') sentenceRefs!: QueryList<ElementRef>;
    @ViewChildren('tabElement') tabElements!: QueryList<ElementRef>;
    formRenderComponent: DynamicFormRendererComponent;
    initialFormData: any;
    currentFormId: any;
    /**
     * Constructor
     */
    constructor(
        private auth_service: AuthService,
        private _activatedRoute: ActivatedRoute,
        private _commonService: CommonService,
        private renderer: Renderer2,
        private _changeDetectorRef: ChangeDetectorRef,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private confirmationService: FuseConfirmationService,
        private snackbar: SnackbarService,
    ) {
        this.module_code =
            this._activatedRoute.snapshot.paramMap.get('module_code');
        if (this.dialogData) {
            this.data = cloneDeep(this.dialogData);
        }
    }

    visibleTabsCount = 6; // Number of tabs visible at a time
    startIndex = 0;
    endIndex = this.visibleTabsCount;
      // To show only tabs in the current range
//   isTabVisible(index: number): boolean {
//     return index >= this.startIndex && index < this.endIndex;
//   }

  // Navigate to the next set of tabs
 
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.loadTabStyles();
        this.userInfo = this.auth_service.userInfo;
        this.loadData();
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }

                // Mark for check
                window.dispatchEvent(new Event('resize'))
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            this.loadData();
            window.dispatchEvent(new Event('resize'))
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }


  ngAfterViewInit() {
    this.sentenceRefs.forEach((elementRef) => {
        const element = elementRef.nativeElement;
        this.checkOverflow(element);
      });  }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    loadData() {
        this.tabsData = [];
        this.getDataByTenant();
        if (this.tabPosition == 'Top') {
            this.loadTabStyles();
        }
        if (this.data) {
            this.loadListViewTabs();
        } else {
            this.getTabsData();
        }
        this._changeDetectorRef.markForCheck();
    }

    getDataByTenant() {
        if(this.userInfo?.tenant_id){
            this._commonService
                .getDataById(MODELS_CONSTANTS.TENANTS, this.userInfo?.tenant_id)
                .subscribe({
                    next: (response: any) => {
                        if (response?.status === 200) {
                            this.userByTenantId = response?.data;
                            this.tabPosition = this.userByTenantId.tab_position;
                            this.tabProperties = this.userByTenantId.tab_properties;
                            this._changeDetectorRef.markForCheck();
                        }
                    },
                    error: (err) => {
                        console.error(err);
                    },
                });
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
      this.loadTabStyles();
    }

    loadTabStyles() {
        if(!this.is_menu){
            setTimeout(() => {
                const navContainerEle = document.getElementById('nav-container');
                const navTabsEle = document.getElementById('nav-tabs');
                const navContentEle = document.getElementById('nav-content');
                if (navContainerEle && navTabsEle && navContentEle) {
                this.renderer.setStyle(
                    navContentEle,
                    'height',
                    `${
                    navContainerEle.clientHeight -
                    (navTabsEle.clientHeight + 10)
                    }px`
                );
                this._changeDetectorRef.detectChanges();
                }
            });
        }
    }
    
    async loadDynamicComponent(tab: any, tabElement?: HTMLElement): Promise<void> {
        tab.isActive = true;
        for (let item of this.tabsData) {
            if (tab?.id !== item?.id) {
                item.isActive = false;
            }
        }
        const componentRef = tab?.component_ref;
        let component;
        if (componentRef) {
            if(!tab?.component) {
                if(componentRef === 'list_view'){
                    const { ListComponent } = await import('app/modules/core/list/list.component');
                    component = { component: ListComponent };
                } else {
                    component = COMPONENT_REFERENCE[componentRef];
                }
    
                // if (tab?.componentInputs) {
                //     this.currentComponent.inputs = tab?.componentInputs;
                // }
    
                tab['component'] = component; 
            } else {
                component = tab?.component;
            }

            this._changeDetectorRef.detectChanges();
            if (this.tabPosition == 'Top') {
                this.loadTabStyles();
            }
            if (tabElement) {
                const container = document.getElementById('nav-tabs'); // Parent scrollable container
                if (container) {
                    const containerRect = container.getBoundingClientRect();
                    const tabRect = tabElement.getBoundingClientRect();

                    // Ensure the active tab is fully visible in the viewport
                    const offset = tabRect.left - containerRect.left;
                    const scrollLeft =
                        container.scrollLeft + offset - container.clientWidth / 2 + tabRect.width / 2;

                    container.scrollTo({ left: scrollLeft, behavior: 'smooth' });
                }
            }
        }
        // Handle additional logic for dynamic components
    this._changeDetectorRef.detectChanges();

    if (this.tabPosition === 'Top') {
        this.loadTabStyles();
    }

        if (component?.component?.name === 'DynamicFormRendererComponent') {
            if (
                this.ngComponentOutlet &&
                this.ngComponentOutlet.ngComponentOutlet.name ===
                    'DynamicFormRendererComponent'
            ) {
                const componentInstance =
                this.ngComponentOutlet['_componentRef'].instance;
                this.formRenderComponent = componentInstance;

                this.formRenderComponent.show_loader();

                setTimeout(async () => {
                    this.initialFormData = await this.formRenderComponent?.getCurrentFormData();
                    this.formRenderComponent.hide_loader();
                }, 3000);
                
            } else {
                this.formRenderComponent = null;
            }
        } else {
            this.formRenderComponent = null;
        }
    }

    getTabsData() {
        if(this.module_code) {
            this._commonService
                .getDataByFields(MODELS_CONSTANTS.VALUE_SET_DETAILS, {
                    vs_code: VALUE_SET_CODE_CONSTANTS.TAB_COMPONENT,
                    vsd_code: this.module_code,
                })
                .subscribe({
                    next: (response: IResponse<any>) => {
                        if (response?.status === 200 && response?.data?.length) {
                            this.tabsData = response?.data?.map((tab) => {
                                const obj: ITab = {
                                    name: tab?.vsd_value,
                                    component_ref: tab?.vsd_property_1,
                                    icon: tab?.vsd_property_2,
                                    id: tab?._id,
                                };
                                return obj;
                            });
                            this.loadDynamicComponent(this.tabsData[0]);
                        }
                    },
                    error: (err) => {
                        console.error(err);
                    },
                });
        }
    }

    loadListViewTabs() {
        if (this.data.form) {
            const { componentRef, name, is_menu, ...rest } = this.data?.form;
            if (is_menu) {
                this.is_menu = is_menu;
            }
            const tab: ITab = {
                name,
                description: `${name} form`,
                icon: 'heroicons_outline:home',
                id: generateUniqueId(),
                component_ref: componentRef,
                componentInputs: {
                    data: {
                        ...rest,
                        row_data: this.data?.row_data ? this.data?.row_data : {},
                        parent_row_data: this.data?.parent_row_data ? this.data?.parent_row_data : {},
                        close_dialog: this.closeDialog
                    }
                },
            };
            this.tabsData.push(tab);
        }
    
        if (this.data.listActions && this.data.listActions?.length) {
            this.data.listActions.forEach((action) => {
                if (this.data.isWorkflowTabs || action.display_at_form !== false) {
                    if (action?.action_type !== 'api' && action?.action_type !== 'redirection') {
                        let inputes = {};
                        if (action?.action_type === "list_view") {
                            inputes['listId'] = action.list_view;
                            inputes['row_data'] = this.data?.row_data ? this.data?.row_data : {};
                            inputes['parent_row_data'] = this.data?.row_data ? this.data?.row_data : {};
                            inputes['extra'] = action?.extra || null;
                        } else if (action?.action_type === "form_render") {
                            inputes["data"] = {
                                id: this.data?.form?.id || this.data?.row_data?._id,
                                form_id: action.form_render,
                                title: action?.name || action?.tooltip,
                                row_data: this.data?.row_data ? this.data?.row_data : {},
                                parent_row_data: this.data?.parent_row_data ? this.data?.parent_row_data : {},
                                params: action.params && action.params.length ? action.params : [],
                                is_from_board: this.data?.is_from_board ? true : false,
                                dynamic_tabs_data: this.data,
                                dataEvent: this.receiveData,
                                close_dialog: this.closeDialog,
                                isReturnAddData: this.data?.isReturnAddData,
                            };
                        } else {
                            if (COMPONENT_REFERENCE?.[action?.action_type]?.inputs) {
                                const input = COMPONENT_REFERENCE?.[action?.action_type]?.inputs;
                                const keys = Object.keys(input);
                                inputes[keys[0]] = this.data?.row_data ? this.data?.row_data : {};
                            } else {
                                inputes['row_data'] = this.data?.row_data ? this.data?.row_data : {};
                            }
                        }
                        const tab: ITab = {
                            name: action?.tooltip,
                            description: action?.description,
                            icon: action?.icon,
                            component_ref: action?.action_type,
                            componentInputs: inputes,
                            id: action?._id,
                        };
                        this.tabsData?.push(tab);
                    }
                }
            });
        }
    
        if (this.tabsData?.length == 1 && this.tabsData[0]?.component_ref === 'form_render' && !this.is_menu) {
            this.tabsData[0].componentInputs.data['show_close'] = true;
            this.show_close = false;
        } else {
            this.show_close = true;
        }
    
        let default_tab_id = this.data.default_tab_id;
        let default_tab_index = 0;
        if (default_tab_id) {
            const index = this.tabsData.findIndex(tab => tab.id === default_tab_id);
            if (index !== -1) {
                default_tab_index = index;
            }
        }
    
        this.loadDynamicComponent(this.tabsData[default_tab_index]);
        this.selectedTab = this.tabsData[default_tab_index]?.id;
    
        if (this.tabsData.length < 2) {
            this.hideTabs = true;
        } else {
            this.hideTabs = false;
        }
    }

    receiveData = (data: any) => {
        this.receivedData = data?.message;
        this.dataEvent.emit(data);
        if(this.eventFromChild){
            this.eventFromChild(data);
        }
        this.closeDialog(true);
    }

     // closeDialog(isRefresh:any = false) {
    //     if (this.data?.is_from_board) {
    //         this?.data?.close_dialog(isRefresh);
    //     } else {
    //         if(this.data?.close_dialog){
    //             this?.data?.close_dialog();
    //         } else if (this.data?.form?.close_dialog){
    //             this.data?.form?.close_dialog();
    //         }
    //     }
    // }

    closeDialog = async (isRefresh: boolean = false, isConfirmation: boolean = true, forceClose: boolean = false, data = null) => {
        if(isConfirmation){
            const hasChanges = await this.checkFormChanges();
            this.initialFormData = null;
        
            if (hasChanges) {
                const dialogRef = this.confirmationService.open({
                    title: 'Unsaved Changes',
                    message: 'There are unsaved changes. <br> Do you want to save them before closing?',
                    actions: {
                        confirm: {
                            show: true,
                            label: 'No',
                            color: 'warn'
                        },
                        cancel: {
                            show: true,
                            label: 'Yes'
                        }
                    }
                });
        
                dialogRef.afterClosed().subscribe((result) => {
                    if (result === 'confirmed') {
                        this.performCloseDialog(isRefresh, forceClose, data);
                    }
                });
            } else {
                this.performCloseDialog(isRefresh, forceClose, data);
            }
        } else {
            this.performCloseDialog(isRefresh, forceClose, data);
        }
    }

    private performCloseDialog(isRefresh: any, forceClose: boolean = false, data = null) {
        if(this.tabsData?.length <= 1 || forceClose) {
            if (this.data?.is_from_board) {
                if (typeof this.data?.close_dialog === 'function') {
                    this.data.close_dialog(isRefresh);
                }
            } else {
                if(!data) {
                    let row_id = this.data?.row_data?._id || null;
                    if(!row_id) row_id = this.currentFormId;
                    
                    data = row_id || null;
                }
                let closeDialogCalled = false;
                if (typeof this.data?.close_dialog === 'function') {
                    this.data.close_dialog(data, this.data?.row_data);
                    closeDialogCalled = true;
                }
        
                if (typeof this.data?.form?.close_dialog === 'function') {
                    this.data.form.close_dialog(data);
                    closeDialogCalled = true;
                }
        
                if (!closeDialogCalled) {}
            }
            localStorage.removeItem('formiodata')
        } else {
            if(data) {
                const selectedTabIndex = this.tabsData.findIndex((e) => e.id == this.selectedTab);
                console.log({selectedTabIndex})
                if(selectedTabIndex == 0) {
                    this.currentFormId = data;
                }
            }
        }    
    }
    
    async checkFormChanges(): Promise<boolean> {
        if (this.formRenderComponent) {
            if(this.formRenderComponent?.form_read_only()){
                return false;
            } else {
                const currentFormData = await this.formRenderComponent.getCurrentFormData();
                // console.log(cloneDeep(this.initialFormData));
                // console.log(currentFormData)
                return !isEqual(this.initialFormData, currentFormData);
            }
        } else {
            return false;
        }
    }

    goToTab(tab) {
        this.selectedTab = tab?.id;

        // Close the drawer on 'over' mode
        if (this.drawerMode === 'over') {
            this.drawer.close();
        }
        this.loadDynamicComponent(tab);
    }

    scroll(direction) {
        const tabsContainer = document.querySelector('.tabs-container');
        if (tabsContainer) {
            if (direction === 'left') {
                tabsContainer.scrollBy({
                    left: -500,
                    behavior: 'smooth',
                });
            } else {
                tabsContainer.scrollBy({
                    left: 500,
                    behavior: 'smooth',
                });
            }
        }
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // Activate previous tab
    activatePreviousTab(): void {
        const currentIndex = this.tabsData.findIndex(tab => tab.isActive);
        const previousIndex = currentIndex > 0 ? currentIndex - 1 : this.tabsData.length - 1;
        this.activateTabByIndex(previousIndex);
    }

    activateNextTab(): void {
        const currentIndex = this.tabsData.findIndex(tab => tab.isActive);
        const nextIndex = currentIndex < this.tabsData.length - 1 ? currentIndex + 1 : 0;
        this.activateTabByIndex(nextIndex);
    }


    // Helper method to activate tab by index
    activateTabByIndex(index: number): void {
        const tabElements = document.querySelectorAll('#nav-tabs li');
        this.tabsData.forEach((tab, i) => {
            tab.isActive = i === index;
            if (i === index) {
                const tabElement = tabElements[i] as HTMLElement;
                this.loadDynamicComponent(tab, tabElement);
                if (tabElement) {
                    const container = document.getElementById('nav-tabs'); // Parent scrollable container
                    if (container) {
                        const containerRect = container.getBoundingClientRect();
                        const tabRect = tabElement.getBoundingClientRect();
    
                        // Calculate the horizontal scroll offset
                        const offset =
                            tabRect.left - containerRect.left - container.clientWidth / 2 + tabRect.width / 2;
                        // Scroll the container smoothly
                        container.scrollBy({ left: offset, behavior: 'smooth' });
                    }
                }
    
            }
        });
    }
   
     checkOverflow(element: HTMLElement): void {
        this.isOverflow = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        this._changeDetectorRef.detectChanges();
        }
    
    getDrawerClass(): any {
        switch (this.tabProperties) {
          case 'type4':
            return 'small-drawer';
          default:
            return 'full-width-drawer';
        }
      }
      setTabProperties(type: string) {
        this.tabProperties = type;
      }
    
}
