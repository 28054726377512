import {
    HttpClient,
    HttpHeaders
} from '@angular/common/http';
import { Injectable, Injector, inject } from '@angular/core';
import { Observable, map, of, tap, throwError } from 'rxjs';

import { AuthUtils } from 'app/core/auth/auth.utils';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SocketService } from 'app/shared/services/socket/socket.service';
import { environment } from 'environments/environment';
import { jwtDecode } from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class AuthService {
    // private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private base_url: string = `${environment.apiRoot}/auth`;
    private commonService!: CommonService;
    headers: any;
    constructor(
        private _socketService: SocketService,
        private injector: Injector
    ) {
        //this.headers = new HttpHeaders().set('Authorization', getToken());
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set access_token(token: string) {
        localStorage.setItem('access_token', token);
    }

    get access_token(): string {
        return localStorage.getItem('access_token') ?? '';
    }

    set userInfo(info: any) {
        localStorage.setItem('user_info', JSON.stringify(info));
    }

    get userInfo(): any {
        return JSON.parse(localStorage.getItem('user_info') ?? '{}');
    }

    set contactInfo(info: any) {
        localStorage.setItem('contact_info', JSON.stringify(info));
    }

    get contactInfo(): any {
        return JSON.parse(localStorage.getItem('contact_info') ?? '{}');
    }

    get _authenticated(): boolean {
        return !!localStorage.getItem('access_token');
    }

    private get _commonService() {
        if (!this.commonService) {
          this.commonService = this.injector.get(CommonService);
        }
        return this.commonService;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }
        // Check the access token availability
        if (!this.access_token) {
            return of(false);
        }
        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.access_token)) {
            return of(false);
        }
    }

    /**
     * Sign up OTP
     *
     * @param user
     */
    signup_otp(user: { phone: string; email_id: string }): Observable<any> {
        return this._httpClient.post(`${this.base_url}/signup_otp`, user);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signup(user: {
        name: string;
        email_id: string;
        phone: string;
        password: string;
        confirm_password: string;
        OTP: string;
    }): Observable<any> {
        return this._httpClient.post(`${this.base_url}/signup`, user);
    }

    /**
     * Sign up OTP
     *
     * @param user
     */
    activate_account(user: {
        phone: string;
        email_id: string;
    }): Observable<any> {
        return this._httpClient.post(`${this.base_url}/activate_account`, user);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signin(credentials: {
        email_id: string;
        password: string;
    }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }
        return this._httpClient
            .post(`${this.base_url}/signin`, credentials)
            .pipe(
                tap((response: any) => {
                    if (response?.status === 200 && response?.data) {
                        this.access_token = response?.data;
                    }
                })
            );
    }

    getAuthToken(user_id: string, role_id: string): Observable<any> {
        this.headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${localStorage.getItem('access_token')}`
        );
        return this._httpClient.get(`${this.base_url}/generate_token`, {
            headers: this.headers,
            params: { user_id, role_id },
        }
        ).pipe(
            tap(async (response: any) => {
                if (response?.status === 200 && response?.data) {
                    this.access_token = response?.data;
                    let newUserInfo: any = jwtDecode(response?.data);
                    let roleResponse: any = await this._commonService.getDataById(MODELS_CONSTANTS.ROLES, newUserInfo.role_id).toPromise();
                    if (roleResponse?.status === 200 && roleResponse?.data) {
                        let userInfo = this.userInfo;

                        const index = userInfo.secondary_role_id.findIndex((e) => e === newUserInfo.role_id);
                        if(index !== -1) {
                            userInfo.secondary_role_id.splice(index, 1);
                        }
                        userInfo.secondary_role_id.push(userInfo.role_id);

                        const roleIndex = userInfo.secondary_roles.findIndex((e) => e._id === newUserInfo.role_id);
                        if(roleIndex !== -1) {
                            userInfo.secondary_roles.splice(roleIndex, 1);
                        }
                        userInfo.secondary_roles.push({ _id: userInfo?.role_id, name: userInfo?.roleName });
                        userInfo.secondary_roles = userInfo.secondary_roles.filter((obj, index, self) =>
                            index === self.findIndex((o) => o._id === obj._id)
                        );

                        userInfo.roleName = roleResponse?.data.role_name;
                        userInfo.role_id = newUserInfo?.role_id;
                        this.userInfo = userInfo;
                    }
                }
            })
        );
    }

    signout(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_info');
        localStorage.removeItem('contact_info');
        this._socketService.disconnect();

        // Return the observable
        return of(true);
    }

    forgot_password(body: any) {
        return this._httpClient
            .post<any>(`${this.base_url}/forgot_password`, body)
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    forgot_password_otp_verify(body: any) {
        return this._httpClient
            .post<any>(`${this.base_url}/forgot_password_otp_verify`, body)
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    reset_password(body: any) {
        return this._httpClient
            .post<any>(`${this.base_url}/reset_password`, body)
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    reset_expired_password(body: any) {
        return this._httpClient
            .post<any>(`${this.base_url}/reset_expired_password`, body)
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    createAccount(body: any): Observable<any> {
        return this._httpClient
            .post<any>(`${this.base_url}/create_account`, body)
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    change_password(body: any) {
        this.headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${localStorage.getItem('access_token')}`
        );
        return this._httpClient
            .post<any>(`${this.base_url}/change_password`, body, {
                headers: this.headers,
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    sendAuthOtp(email_id: string): Observable<any> {
        return this._httpClient.post(`${this.base_url}/two_step_auth`, { email_id })
    }

    verifyAuthOtp(email_id: string, otp: string ): Observable<any> {
        return this._httpClient.post(`${this.base_url}/otp_verify`, { email_id, otp })
    }
}
