<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <div class="flex settings-responce sm:flex-row flex-0 sm:items-center sm:justify-between p-6  
        sm:px-10 bg-default dark:bg-transparent relative">
        <div class="flex-1 min-w-0 setting-padding">
            <div class="flex items-center">
                <div class="text-3xl mb-1 font-bold tracking-tight leading-none">
                    Security
                </div>
            </div>
        </div>

        <div class="flex items-center justify-end ml-auto">
            <button class="ml-4" mat-flat-button type="button" [color]="'primary'" (click)="saveSecurity()">Save
            </button>
        </div>
    </div>

    <div class="flex-auto settings-res p-6 sm:p-10 rounded-t-2xl shadow overflow-y-auto" cdkScrollable>
        <form [formGroup]="securityForm" class="px-6">

            <div class="w-full">
                <div class="text-xl">Change your password</div>
                <!-- <div class="text-secondary">You can only change your password twice within 24 hours!</div> -->
            </div>

            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">

                <div class="sm:col-span-4">
                    <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Current password</mat-label>
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:key'" matPrefix></mat-icon>
                        <input #current_pass [formControlName]="'current_password'" type="password" matInput>
                        <button mat-icon-button type="button"
                        (click)="current_pass.type === 'password' ? current_pass.type = 'text' : current_pass.type = 'password'"
                        matSuffix>
                        <mat-icon class="icon-size-5" *ngIf="current_pass.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon class="icon-size-5" *ngIf="current_pass.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                    </button>
                    </mat-form-field>
                </div>

                <div class="sm:col-span-4">
                    <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>New password</mat-label>
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:key'" matPrefix></mat-icon>
                        <input #pass_key [formControlName]="'new_password'" type="password" matInput
                            (keyup)="submitted = true">
                            <button mat-icon-button type="button"
                            (click)="pass_key.type === 'password' ? pass_key.type = 'text' : pass_key.type = 'password'"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="pass_key.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="pass_key.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                    </mat-form-field>
                    <div *ngIf="submitted && securityForm.controls['new_password'].errors">
                        <span *ngIf="securityForm.controls['new_password'].errors.pattern">
                            Minimum 8 characters. Must include numbers,Uppercase, letters and special
                            characters.
                        </span>
                    </div>
                </div>

                <div class="sm:col-span-4">
                    <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Confirm password</mat-label>
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:key'" matPrefix></mat-icon>
                        <input #confirm_pass_key [formControlName]="'confirm_password'" type="password" matInput
                            (keyup)="passwordMatchValidator($event.target.value,pass_key.value)">
                            <button mat-icon-button type="button"
                            (click)="confirm_pass_key.type === 'password' ? confirm_pass_key.type = 'text' : confirm_pass_key.type = 'password'"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="confirm_pass_key.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="confirm_pass_key.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                    </mat-form-field>
                    <div *ngIf="passwordMatch">
                        <mat-error *ngIf="passwordMatch">Password Doesn't Match.</mat-error>
                    </div>
                </div>

            </div>
            <!-- Divider -->
            <div class="my-10 border-t"></div>


            <!-- Preferences -->
            <div class="w-full">
                <div class="text-xl">Security preferences</div>
                <div class="text-secondary">Keep your account more secure with following preferences.</div>
            </div>

            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">

                <div class="sm:col-span-4 flex items-center justify-between cursor-pointer"
                    (click)="twoStepToggle.toggle(); authChanged = true">
                    <div class="flex-auto">
                        <div class="leading-6 font-medium">Enable 2-step authentication</div>
                        <div class="text-md text-secondary">
                            Protects you against password theft by requesting an authentication code via SMS on every
                            login.
                        </div>
                    </div>
                    <mat-slide-toggle class="ml-4" (click)="twoStepToggle.toggle(); authChanged = true" [color]="'primary'" [formControlName]="'two_step_auth'"
                        #twoStepToggle>
                    </mat-slide-toggle>
                </div>

                <div class="sm:col-span-4 flex items-center justify-between cursor-pointer"
                    (click)="askPasswordChangeToggle.toggle(); authChanged = true">
                    <div class="flex-auto">
                        <div class="leading-6 font-medium">Ask to change password on every 3 months</div>
                        <div class="text-md text-secondary">
                            A simple but an effective way to be protected against data leaks and password theft.
                        </div>
                    </div>
                    <mat-slide-toggle class="ml-4" (click)="askPasswordChangeToggle.toggle(); authChanged = true" [color]="'primary'" [formControlName]="'askPasswordChange'"
                        #askPasswordChangeToggle>
                    </mat-slide-toggle>
                </div>

            </div>
            <!-- Divider -->
            <div class="my-10 border-t"></div>

        </form>
    </div>
</div>