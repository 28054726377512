// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    apiRoot: 'http://143.244.142.190/api/v1',
    socketApiRoot: 'http://143.244.142.190',
    dateFormat: 'dd/mm/yy',
    // vortexLogo: 'assets/images/logo/vortex.png',
    vortexLogo: 'assets/images/logo/logo.svg',
    appURL: 'http://www.integratatech.ai/', 

    FileStorageURL: 'https://vortexstorage.s3.ap-southeast-2.amazonaws.com/',
    logoShortUrl: 'assets/images/logo/logo-short.svg',
    // vortexLogoDark: 'assets/images/logo/vortexlogotext.png',
    vortexLogoDark: 'assets/images/logo/logo-text-on-dark.svg',
    vortexToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMmMyNjk2YWYtOTcxYy00MDU1LWE0OGQtNDlkMjUxNGU0MjZlIiwiaWF0IjoxNjkxNTc1OTcwfQ.kD2HUTA-aN7J-_WGfWYHKn2BF0LNHBQwhCpEapxgppE',
    firebase: {
        apiKey: "AIzaSyDfGr1KAjD0w79k52WQ6aQDuceXWPTrRGQ",
        authDomain: "vortex-9d665.firebaseapp.com",
        projectId: "vortex-9d665",
        storageBucket: "vortex-9d665.appspot.com",
        messagingSenderId: "657892707294",
        appId: "1:657892707294:web:2deafeb0f5b5a82d9c6b4f",
        measurementId: "G-D1KXEGSF6W",
        vapidKey : "BEaFQsk_M8ddRb2Gg1b_GmMZmvaz5hI1VcLzMUX3PMGbPff2sE22NX-7PZfEVCpYPHb0_n7dXgBSbkwkEaBXxZA"
    },
    tempUserId: '2c2696af-971c-4055-a48d-49d2514e426e',
    superAdminId: '653b3b3a81e2790486e6e664',
    msalConfig: {
        auth: {
            clientId: 'ed12acc6-e0f8-48c9-9a77-863ad67aa278',
            authority: 'https://login.microsoftonline.com/organizations/',
            redirectUri: 'http://localhost:4200/home',
            postLogoutRedirectUri: 'http://localhost:4200/'
        }
    },
    apiConfig: {
        scopes: [''],
        uri: ''
    },
    n8nUrl: 'http://127.0.0.1:5678'
    // isSideMenuEnabledForTabs: true
};
