import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(private _snackbar: MatSnackBar) {}

    success(message: string) {
        this._snackbar.open(message, 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1000,
            panelClass: 'success-snackbar'
        });
    }

    error(message: string) {
        this._snackbar.open(message, 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
            panelClass: 'error-snackbar'
        });
    }

    warning(message: string) {
        this._snackbar.open(message, 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'warning-snackbar'
        });
    }
}
