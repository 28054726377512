import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Injectable, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, TemplateRef, ViewChild, WritableSignal, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import moment from 'moment';

import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AuthService } from 'app/core/auth/auth.service';
import { ListService } from 'app/modules/core/list/list.service';
import { API_REGISTRY_CONSTANTS } from 'app/shared/constants/api-registry.constants';
import { COMPONENT_REFERENCE } from 'app/shared/constants/component-reference.constants';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { SHARED_CONSTANTS } from 'app/shared/constants/shared.constants';
import { TEMPLATES_CONSTANTS } from 'app/shared/constants/templates.constants';
import { VALUE_SET_CODE_CONSTANTS } from 'app/shared/constants/value-set.contants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { environment } from 'environments/environment';
import { cloneDeep, isEqual, omitBy } from 'lodash';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { Subject, takeUntil } from 'rxjs';
import { JsonDiffService } from 'app/shared/services/json-diff/json-diff.service';
import { Diff2HtmlUI, Diff2HtmlUIConfig } from 'diff2html/lib/ui/js/diff2html-ui-slim.js';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-form',
    templateUrl: './forms-renderer.component.html',
    styleUrls: ['./forms-renderer.component.scss'],
})
@Injectable()
export class DynamicFormRendererComponent implements OnInit, OnChanges {
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    @ViewChild('form_required_fields') form_required_fields: TemplateRef<any>;
    @ViewChild('pdf_viewer') pdf_viewer: ElementRef<HTMLDivElement>;
    @ViewChild('json_diff_viewer') json_diff_viewer: ElementRef<HTMLDivElement>;
    @Input() data: any;
    @Output() dataEvent: EventEmitter<any> = new EventEmitter<any>();
    refreshForm: any;
    FormHeader: string = 'Add';
    formdata: any = {};
    form_details: any = {};
    form_module_code: any;
    form_module_id: string;
    form_module_code_url: any;
    form_module_code_category: any;
    form_module_doc_no_flag: boolean = false; 
    submissiondata: { data: any };
    form_id: any;
    formname: any;
    collection_name: any;
    form_data_id: any;
    id: any;
    form_code: any;
    active_version: any;
    rows: any = [];
    vortexModule: any;
    vortexUpdateModule: any;
    updateData: Boolean = false;
    workflowcode: any;
    stepData: any;
    drawerWidth: string = '100%';
    classType: string = 'Icon-Size-md'
    conversationData: any = {};
    actionComponent: { component: any, inputs?: any };
    formFooterData: { footerPermissions?: any, footerData?: any, isApproval?: boolean, formId?: string, approvalData?: any } = {};
    formHeaderData: { form_header_actions?: any[] } = {};
    menuPermissions: any = { write: true, read: true };
    form_read_only: WritableSignal<boolean> = signal(false);
    form_show: any = false;
    showLoader: WritableSignal<boolean> = signal(true);
    loaderCount: number = 0;
    showPdfLoader: WritableSignal<boolean> = signal(false);
    progressValue: number = 0;
    allRequiredFields: string[];
    requiredFields: string[];

    // PDF VIEWER
    pdfSrc: WritableSignal<string> = signal('');
    showPdfViewer: WritableSignal<boolean> = signal(false);
    pageZoom: WritableSignal<number> = signal(1);
    minZoom: number = .1;
    maxZoom: number = 5;
    currentPdfPage: number = 1;
    totalPdfPages: number;
    moduleSchemaKeys = [];
    initialFormData: any;
    pdfViewerStyle: string = 'height: 588px';
    showJsonDiffViewer: WritableSignal<boolean> = signal(false);

    save = {
        "label": "Save",
        "showValidations": false,
        "disableOnInvalid": true,
        "disabled": false,
        "tableView": false,
        "key": "save",
        "type": "button",
        "customClass": "pull-right mb-2",
        "saveOnEnter": false,
        "input": true,
        "action": "event",
        "event": "onSave"
    };
    submit = {
        "label": "Submit",
        "showValidations": false,
        "disableOnInvalid": true,
        "tableView": false,
        "key": "submit",
        "type": "button",
        "customClass": "pull-right mr-2 mb-2",
        "saveOnEnter": false,
        "input": true,
        "action": "event",
        "event": "onSubmit"
    };
    resubmit = {
        "label": "Re-Submit",
        "showValidations": false,
        "disableOnInvalid": true,
        "tableView": false,
        "key": "resubmit",
        "type": "button",
        "customClass": "pull-right mr-2 mb-2",
        "saveOnEnter": false,
        "input": true,
        "action": "event",
        "event": "onResubmit"
    };

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    tenants: any;
    ai_module_code: string;

    constructor(
        private _router: Router,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private _commonService: CommonService,
        private snackbar: SnackbarService,
        private authService: AuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _listService: ListService,
        private dialog: MatDialog,
        private confirmationService: FuseConfirmationService,
        private jsonDiffService: JsonDiffService,
        private _notificationsService: NotificationsService,
        private domSanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.show_loader();
        const userInfo = this.authService.userInfo;;
        this._listService.permissions$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((menu: any) => {
                if (menu?.permissions) {
                    this.menuPermissions = menu.permissions;
                    if(this.data.permissions) {
                        this.menuPermissions = this.data.permissions;
                    }
                    if(this.data?.handle_clock) {
                        this.menuPermissions.write = true;
                    }
                    this.form_read_only.set(!this.menuPermissions.write);
                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                }

                if(menu?.params && menu?.params?.length){
                    this.ai_module_code = menu?.params?.find((e) => e?.code === 'ai_module_code')?.value;
                }
            });

        this.submissiondata = { data: {} };
        if (this.dialogData && (this.dialogData?.form_code || this.dialogData?.form_id)) {
            this.data = this.dialogData;
        }
        (async () => {
            this.FormHeader = this.data.title;
            this.formFooterData.footerData = this.submissiondata.data;
            const { show_next, row_data, user_type, close_dialog, show_approval } = this.data?.dynamic_tabs_data || {};
            this.formFooterData.approvalData = { show_next, row_data, user_type, close_dialog, show_approval };
            this.formFooterData = { ...this.formFooterData };
            const tenant_response: any = await this._commonService.getDataById(MODELS_CONSTANTS.TENANTS, userInfo.tenant_id).toPromise();
            if(tenant_response?.status == 200 && tenant_response?.data) {
                this.tenants = tenant_response?.data;
            }
            await this.get_form_details(this.data);
           
        })();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if ('data' in changes) {
            this.show_loader();

            this.formFooterData.footerData = this.data?.row_data;
            const { show_next, row_data, user_type, close_dialog, show_approval } = this.data?.dynamic_tabs_data || {};
            this.formFooterData.approvalData = { show_next, row_data, user_type, close_dialog, show_approval };
            this.formFooterData = { ...this.formFooterData };

            await this.get_form_details(this.data);
            this.FormHeader = this.data.title;
            if (this.matDrawer.opened) {
                this.matDrawer.close();
            }
            this.actionComponent = null;
        }
    }

    // Get form details to load forms
    async get_form_details(data: any) {
        try {
            this.form_read_only.set(!this.menuPermissions.write);
            this.form_show = false;
            this.submissiondata = { data: {} };
            this.formFooterData.footerData = this.submissiondata.data;
            this.formFooterData = { ...this.formFooterData };

            const result = await this._commonService.getDataByField(
                MODELS_CONSTANTS.FORM_SETP,
                data.form_code ? 'form_code' : '_id',
                data.form_code ? data.form_code : data.form_id
            ).toPromise();
            if (result?.status === 200) {
                this.form_details = result.data[0];
                this.formFooterData.isApproval = this.form_details?.is_approval;
                this.formFooterData.formId = this.form_details?._id;
                this.form_module_id = result?.data[0]?.module_id;
                this.get_form_actions(this.form_details?._id);
                this.get_form_footers(this.form_details?._id);
                await this.get_form_module_details(result?.data[0]?.module_id);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async get_form_actions(form_id) {
        try {
            const result = await this._commonService
                .getDataByField(
                    MODELS_CONSTANTS.FORM_ACTIONS,
                    'form_id',
                    form_id
                )
                .toPromise();
            if (result?.status === 200) {
                // this.form_header_actions = result.data || [];
                this.formHeaderData.form_header_actions = result.data || [];
                this.formHeaderData = { ...this.formHeaderData };
            }
        } catch (error) {
            console.error(error);
        }
    }

    async get_form_footers(form_id) {
        try {
            const result = await this._commonService
                .getDataByField(MODELS_CONSTANTS.FORM_FTR, 'form_id', form_id)
                .toPromise();
            if (result?.status === 200) {
                this.formFooterData.footerPermissions = result.data?.[0];
                this.formFooterData = { ...this.formFooterData }
            }
        } catch (error) {
            console.error(error);
        }
    }

    // Get form selected module details
    async get_form_module_details(module_id) {
        const module_response = await this._commonService.getDataByFields(
            MODELS_CONSTANTS.MODULES,
            { module_id }
        ).toPromise();
        if (module_response?.status === 200 && module_response?.data.length > 0) {
            this.form_module_code = module_response.data[0].module_code;
            this.form_module_code_category = module_response.data[0].module_category;
            this.form_module_doc_no_flag = module_response.data[0]?.isDocNo || false;
            // this.formFooterData.isApproval = module_response?.data[0]?.isApproval || false;
            this.formdata = await this.select_url_replace(this.form_details.form_data);
            if (this.authService.userInfo.client_id) {
                this.formdata.client = true;
            } else {
                this.formdata.client = false;
            }
            if (this.authService.userInfo._id == this.submissiondata?.data?.created_by) {
                this.formdata.requester = true;
            } else {
                this.formdata.requester = false;
            }

            this.refreshForm = new EventEmitter();
            await this.get_form_module_url_details(module_response.data[0].module_category);
        }
    }

    // Get form selected module URL details to call API
    async get_form_module_url_details(module_category) {
        const module_url_response = await this._commonService.getDataByFields(
            MODELS_CONSTANTS.VALUE_SET_DETAILS,
            {
                vs_code: VALUE_SET_CODE_CONSTANTS.API_REGISTRY_BASE_URL,
                vsd_code: module_category
            }
        ).toPromise();
        if (module_url_response?.status === 200 && module_url_response?.data.length > 0) {
            this.form_module_code_url = module_url_response.data[0].vsd_property_1;
            if (this.data.is_from_board) {
                this.get_task_form_map();
            } else if (this.data.id && this.form_details.init_action) {
                // Call get id details API using init action
                await this.get_id_details();
            } else if (this.form_details.init_action) {
                await this.get_id_details();
            } else if (this.data.id) {
                // Call get id details API using module code url
                await this.get_id_details_module_url(this.data.id)
            } else if (this.data.form_data) { 
                this.submissiondata = { data: this.data.form_data };
                this.addSaveSubmit(0);
                this.form_show = true;
            } else if(this.data.module_schema){
                this.submissiondata = { data: { module_schema : this.data.module_schema } };
                this.addSaveSubmit(0);
                this.form_show = true;
            } else if(this.data.action && !this.data.id){
                await this.get_id_details_module_url(null)
            } else {
                this.submissiondata = { data: {} };
                this.addSaveSubmit(0);
                this.form_show = true;
            }

            if (this.form_details.parent_id && this.data.parent_row_data && this.data.parent_row_data.vs_code) {
                this.submissiondata.data[this.form_details.parent_id] = this.data.parent_row_data.vs_code;
            } else if (this.form_details.parent_id && this.data.parent_row_data && this.data.parent_row_data._id) {
                this.submissiondata.data[this.form_details.parent_id] = this.data.parent_row_data._id;
                console.log('this.submissiondata', this.submissiondata); 
            }
            this.submissiondata.data.tenant_id = this.authService.userInfo.tenant_id;
            this.submissiondata.data.userID = this.authService.userInfo._id;

            this.formFooterData.footerData = this.submissiondata.data;
            this.formFooterData = { ...this.formFooterData };
        }
    }

    async get_task_form_map() {
        const task_from_map = await this._commonService.getDataByFields(
            MODELS_CONSTANTS.BOARD_TASK_FORM,
            {
                task_id: this.data.id,
                ref_type_id: this.data.form_id
            }
        ).toPromise();
        if (task_from_map?.status === 200 && task_from_map?.data.length > 0) {
            await this.get_id_details_module_url(task_from_map.data[0].ref_id);
        }
    }

    // Get id details when click edit using init action
    async get_id_details() {
        const apiRegistryResponse = await this._commonService.getDataByField(
            MODELS_CONSTANTS.API_REGISTRIES,
            '_id',
            this.form_details.init_action
        ).toPromise();
        if (
            apiRegistryResponse?.status === 200 &&
            apiRegistryResponse?.data.length > 0
        ) {
            let payload = { module_code: this.form_module_code };
            if (this.data.id) {
                payload['id'] = this.data.id;
            }
            if (this.data.row_data) {
                payload = { ...payload, ...this.data.row_data }
            }
            const response = await this._commonService.executeApiMethod(
                apiRegistryResponse.data[0],
                payload
            );
            if (response?.status === 200) {
                if(response?.data?.length){
                    response.data[0]['all_data'] = cloneDeep(response?.data);
                }
                await this.find_file_components_replace_value(response.data[0]);
            }
        }
    }

    // Get id details when click edit using module code url
    async get_id_details_module_url(id: any) {
        let params: any = {};
        if (this.form_module_code === 'form_footer') {
            params['form_id'] = this.data.id;
        } else {
            if (this.data.params && this.data.params.length && !this.data.is_from_board) {
                let replace_params = this.params_value_replace(this.data.params, this.data.row_data, this.data.parent_row_data);
                if (replace_params && replace_params.length) {
                    replace_params.forEach((p: any) => {
                        params[p.key] = p.value;
                    });
                }
            } else if (this.form_module_code_category === 'CORE') {
                params['_id'] = id;
            } else if (this.form_module_code_category === 'VRM') {
                params['id'] = id;
            }
        }

        const formParams = this.formdata.components.find((e) => e.key === 'form_params');
        if(formParams?.components?.length) {
            params = {};
            for(let param of formParams.components) {
                if(!param?.key?.includes('field_name') && param?.key && param?.defaultValue) {
                    params[param.key] = param.defaultValue;
                }
            }
        }
        const response: any = await this._commonService.callAPI(
            this.form_module_code_url,
            'get',
            this.form_module_code,
            {},
            params
        ).toPromise();
        if (response?.status === 200) {
            if(response?.data?.length){
                response.data[0]['all_data'] = cloneDeep(response?.data);
            }
            await this.find_file_components_replace_value(response?.data[0]);
        }
    }

    async find_file_components_replace_value(data: any) {
        if (!data) data = {};
        let file_upload_components = this.find_file_components(this.formdata.components);

        const update_file = async (data, file_upload_components) => {
            await Promise.all(file_upload_components.map(async (component) => {
                let file_data_id: any = data[component.key];
                if(file_data_id && Array.isArray(file_data_id) && file_data_id?.length > 0) {
                    return;
                }
                if (file_data_id && !Array.isArray(file_data_id)) {
                    if (file_data_id?.includes('/Invoice/')) {
                        let { filename, size } = data;
                        const key = `Invoice/${filename}`;
                        let file_data = {
                            // "_id": data?._id,
                            storage: 'url',
                            url: `https://vortexstorage.s3.amazonaws.com/${key}`,
                            originalName: filename,
                            name: key,
                            size: size,
                        };
                        data[component.key] = [file_data];
                    } else {
                        const response = await this._commonService
                            .getDataById(
                                MODELS_CONSTANTS.FILE_STORAGE,
                                file_data_id
                            )
                            .toPromise()
                            .catch((err) => console.error(err));
                        if (response && response.status === 200) {
                            let { name, size } = response.data;
                            let key = this.file_to_key(response.data);
                            let file_data = {
                                _id: file_data_id,
                                storage: 'url',
                                url: `https://vortexstorage.s3.ap-southeast-2.amazonaws.com/${key}`,
                                originalName: name,
                                name: key,
                                size: size,
                            };
                            data[component.key] = [file_data];
                        }
                    }
                } else {
                    data[component.key] = [];
                }
            }));
        }

        await update_file(data, file_upload_components);

        const data_grid_components = this.find_grid_components(this.formdata.components);
        for(const grid_component of data_grid_components) {
            let grid_file_upload_components = this.find_file_components(grid_component.components);
            if(grid_file_upload_components && grid_file_upload_components?.length) {
                const files = data[grid_component?.key];
                if(Array.isArray(files)) {
                    for(let file of files) {
                        await update_file(file, grid_file_upload_components);
                    }
                }
            }
        }

        if(data?.all_data && data?.all_data?.length){
            for(let item of data?.all_data) {
                await update_file(item, file_upload_components);
            }
        }

        this.addSaveSubmit(data.status);

        if (this.form_details && this.form_details.is_approval && this.form_details.board_id) {
            if (data && (data.status == 1 || data.status == 2 || data.status == 3)) {
                setTimeout(() => {
                    this.form_read_only.set(true);
                }, 500);
            } else {
                this.form_read_only.set(false);
            }
        } else {
            this.form_read_only.set(!this.menuPermissions.write);

            data = this.setFormStatus(data, true)
        };

        data['tenant_details'] = this.tenants;

        this.submissiondata = { data };
        this.formFooterData.footerData = this.submissiondata.data;
        this.formFooterData = { ...this.formFooterData };

        this.form_show = true;

        this.calculateRequiredFields();
        this.initialFormData = await this.getCurrentFormData();
    };

    addSaveSubmit(status) { // null-New; 0-Saved; 1- Submitted; 2-Approved; 3-Rejected; 4-Pushedback

        // Set form as editable if the user has write permissions
        this.form_read_only.set(!this.menuPermissions.write);

        if(this.form_details.form_type != 'custom') {
    
            // Ensure that fields are added to the form if available
            if (this.data?.form_data?.fields?.length) {
                for (let field of this.data.form_data.fields) {
                    this.formdata.components[0]?.components.unshift({
                        label: field.title,
                        showValidations: false,
                        disableOnInvalid: true,
                        tableView: false,
                        key: field.title,
                        type: field.type,
                        saveOnEnter: false,
                        input: true
                    });
                }
            }
        
            // If there are custom buttons, do not proceed further
            if (this.data?.custom_buttons) {
                this.form_read_only.set(false);
                return;
            }

            const lastComponentKey = this.formdata?.components?.[this.formdata.components.length - 1]?.key;

            // Determine whether to show "Save" or "Submit" button based on status
            if (this.form_details.is_approval) {
                this.save.disableOnInvalid = false;

                if (status === 0 || status === null) {
                    if (
                        lastComponentKey !== 'submit' &&
                        lastComponentKey !== 'resubmit' &&
                        lastComponentKey !== 'save'
                    ) {
                        this.formdata.components.push(this.save);
                    }

                    if (lastComponentKey !== 'submit') {
                        this.formdata.components.push(this.submit);
                    }
                } else if (status === 4) {
                    if (
                        lastComponentKey !== 'submit' &&
                        lastComponentKey !== 'resubmit' &&
                        lastComponentKey !== 'save'
                    ) {
                        this.formdata.components.push(this.save);
                    }

                    if (lastComponentKey !== 'resubmit') {
                        this.formdata.components.push(this.resubmit);
                    }
                }
            } else {
                if(this.form_module_code == MODELS_CONSTANTS.AUDIT_TRAIL) {
                    this.save.disabled = true;
                } else {
                    this.save.disabled = false;
                }
                if (
                    lastComponentKey !== 'save' &&
                    this.formdata?.components?.[
                        this.formdata.components.length - 2
                    ]?.key !== 'save'
                ) {
                    this.formdata.components.push(this.save);
                }

                if (
                    this.form_details.form_code === 'USERS' &&
                    lastComponentKey !== 'submit'
                ) {
                    this.formdata.components.push(this.submit);
                }
            }
        }
    
        // Update the form view after a short delay
        setTimeout(() => {
            this.formdata = { ...this.formdata };
            this._changeDetectorRef.detectChanges();
        }, 1000);
        //this.changeForm();
    }



    async select_url_replace(form_data: any, is_after_submit = false) {
        const urlRes = await this._commonService.getDataByField(
            MODELS_CONSTANTS.VALUE_SET_DETAILS,
            'vs_code',
            VALUE_SET_CODE_CONSTANTS.API_REGISTRY_BASE_URL
        ).toPromise();

        let string = JSON.stringify(form_data.components);
        urlRes.data.forEach(url => {
            let regex = new RegExp(`{{${url.vsd_code}}}`, 'g');
            string = string.replace(regex, url.vsd_property_1);
        });
        const token_regex = new RegExp('{{TOKEN}}', 'g');
        string = string.replace(token_regex, this.authService.access_token);
        const module_code_regex = new RegExp('{{module_code}}', 'g');
        string = string.replace(module_code_regex, this.form_module_code);
        let components = JSON.parse(string);
        return { components };
    }

    async onCustomEvent(event: any) {
        let is_taks_create = false;
        if (event.type === 'onSave') {
            // if (!event?.data?.status) {
            //     if (
            //         this.form_details.is_approval &&
            //         this.form_details.board_id
            //     ) {
            //         event.data.status = 0;
            //     } else {
            //         if (this.form_details?.client) {
            //             event.data.status = 1;
            //         } else {
            //             event.data.status = 0;
            //         }
            //     }
            // }

            event.data = this.setFormStatus(event.data, false);
            this.render_formSubmit(event, is_taks_create)
        } else if (event.type === 'onSubmit') {
            if (this.formdata.client) {
                event.data.status = 1;
            } else {
                event.data.status = 1;
            }

            if (this.form_details.is_approval && this.form_details.board_id) {
                is_taks_create = true;
            }
            // this.form_read_only.set(true);
            // this.changeForm();
            this.render_formSubmit(event, is_taks_create, null, true)
        } else if (event.type === 'onNotifyVendor') {
            if(event?.data?._id) {
                this.notifyVendor(event.data);
            } else {
                // if(!event?.data?.status) {
                //     if (this.form_details.is_approval && this.form_details.board_id) {
                //         event.data.status = 0;
                //     } else {
                //         if(this.form_details?.client){
                //             event.data.status = 1;
                //         }else{
                //             event.data.status = 0;
                //         }
                //     }
                // }
                event.data = this.setFormStatus(event.data, false);
                this.render_formSubmit(event, is_taks_create, this.notifyVendor)
            }
        } else if (event.type === 'displayPDF') {
            this.displayPdf(event);
        } else if (event.type === 'onSendAIMessage') {
            this.show_loader();
            const { instructions } = event.data;

            if(!instructions) {
                this.snackbar.error('AI Instructions is required');
                return;
            }
            
            const response = await this._commonService.sendMessageToAI(instructions);

            event.data.response = response;
            this.submissiondata = {...this.submissiondata};
            this.hide_loader();
        } else if(event.type === 'onExcelJsonProcess') {
            if(event?.data?.file_urls && event?.data?.file_urls?.length) {
                this.show_loader();
                const json = await this.getExcelJSON(event.data.file_urls);
                event.data.json_data = JSON.stringify(json, null, 2);
                this.submissiondata = { ...this.submissiondata };
                this.hide_loader();
            }
        } else if (event.type === 'onExcelJsonDownload') {
            this.show_loader();
            let json;
            if(event?.data?.json_data) {
                json = JSON.parse(event?.data?.json_data);
            } else if(event?.data?.file_urls && event?.data?.file_urls?.length) {
                json = await this.getExcelJSON(event.data.file_urls);
            }
            if(json && json?.length) {
                const title = event?.data?.title;
                this.downloadJson(json, title);
            } else {
                this.snackbar.error('No JSON found to download');
            }
            this.hide_loader();
        } else if(event.type === 'onValidateGST') {
            if(!event.data.gstNumber){
                this.snackbar.error('Please enter GST number');
                return;
            }
            this.show_loader();
            const apiRegistryId = API_REGISTRY_CONSTANTS.VALIDATE_GST;
            const data = await this.executeAPI(apiRegistryId, event.data);
            event.data.gst_details = data;
            event.data.validGst = data ? true : false;
            this.submissiondata = { ...this.submissiondata };
            this.hide_loader();
        } else if(event.type === 'onValidatePAN') {
            if(!event.data.panNumber){
                this.snackbar.error('Please enter PAN number');
                return;
            }
            this.show_loader();
            const apiRegistryId = API_REGISTRY_CONSTANTS.VALIDATE_PAN;
            const data = await this.executeAPI(apiRegistryId, event.data);
            event.data.pan_details = data;
            event.data.validPan = data ? true : false;
            this.submissiondata = { ...this.submissiondata };
            this.hide_loader();
        } 
        // TODO: Remove this after cleartax api cross origin configuration is done
        else if (event.type === 'onValidateGSTNode') {
            if(!event.data.gstNumber){
                this.snackbar.error('Please enter GST number');
                return;
            }
            this.show_loader();
            const apiRegistryId = API_REGISTRY_CONSTANTS.VALIDATE_GST_NODE;
            const data = await this.executeAPI(apiRegistryId, event.data);
            event.data.gst_details = data;
            event.data.validGst = data ? true : false;
            this.submissiondata = { ...this.submissiondata };
            this.hide_loader();
        } 
        // TODO: Remove this after cleartax api cross origin configuration is done
        else if (event.type === 'onValidatePANNode') {
            if(!event.data.panNumber){
                this.snackbar.error('Please enter PAN number');
                return;
            }
            this.show_loader();
            const apiRegistryId = API_REGISTRY_CONSTANTS.VALIDATE_PAN_NODE;
            const data = await this.executeAPI(apiRegistryId, event.data);
            event.data.pan_details = data;
            event.data.validPan = data ? true : false;
            this.submissiondata = { ...this.submissiondata };
            this.hide_loader();
        } else if (event?.type === 'onCreateGRN') {
            const reqBody = event?.data;
            const response = await this._commonService
                .create_grn(reqBody)
                .toPromise()
                .catch((err) => {
                    this.snackbar.error(err?.message);
                });

            if(response?.status == 200) {
                this.snackbar.success('GRN Created Successfully');
                if(this.data.close_dialog) {
                    this.data?.close_dialog();
                }
            } else {
                this.snackbar.error(response?.message);
            }
        } else if (event?.type === 'displayJsonDiff') {
            this.displayJSONDiff(event);
        } 

        if(this.data.custom_buttons){
            if(event.type === 'onConfirm'){
                this.data?.close_dialog(event.data);
            }
            if(event.type === 'onCancel'){
                this.data?.close_dialog();
            }
        }
    }

    async displayPdf(event) {
        if(event.data.pdf_url) {
            this.showPdfViewer.set(true);
            setTimeout(() => {
                // const pdfViewer = document.getElementById('pdf-viewer');
                const pdfViewer = this.pdf_viewer?.nativeElement;
                let pdfViewerId = 'form-pdf-viewer';
                if(event.data.pdf_viewer_id) {
                    pdfViewerId += event.data.pdf_viewer_id;
                }
                let formPdfViewer = document.getElementById(pdfViewerId);
                
                if(!formPdfViewer) {
                    formPdfViewer = document.getElementsByName(pdfViewerId)[event?.data?.row_index];
                }

                if(pdfViewer && formPdfViewer) {
                    const maxHeight = formPdfViewer.clientHeight || 700;
                    pdfViewer.style.maxHeight = maxHeight + 'px';
                    this.pdfViewerStyle = `height: ${maxHeight - 112}px;`
                    pdfViewer.classList.remove('hidden');
                    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
                    formPdfViewer.appendChild(pdfViewer);
                    this.pdfSrc.set(event.data.pdf_url);
                }
            }, 500);
        }
    }
    
    async displayJSONDiff(event) {
        if(!event?.data?.jsonToCompare?.length) { 
            this.snackbar.warning('Please select atleast one file');
            return;
        }

        if(event?.data?.jsonToCompare?.length > 2) {
            this.snackbar.warning('Maximum 2 files can be selected for comparison');
            return;
        }

        this.showJsonDiffViewer.set(true);
        setTimeout(() => {
            const jsonToCompare = event?.data?.jsonToCompare;
            const json1 = jsonToCompare[0] || {};
            const json2 = jsonToCompare[1] || {};
            const fileName = `${event?.data?.module_code}-${event?.data?.ref_id}` || 'JSON';

            const diffString = this.jsonDiffService.generateDiff(fileName, json1, json2);

            const container = document.getElementById('json_diff_container');
            const jsonDiffViewer = this.json_diff_viewer?.nativeElement;
            const jsonDiffConfigs: Diff2HtmlUIConfig = {
                outputFormat: 'side-by-side',
                matching: 'lines',
                highlight: true,
                drawFileList: false,
                synchronisedScroll: true,
            } 

            const diff2htmlUi = new Diff2HtmlUI(jsonDiffViewer, diffString, jsonDiffConfigs);
            diff2htmlUi.draw();
            diff2htmlUi.highlightCode();

            if (container && jsonDiffViewer) {
                container.innerHTML = '';
                jsonDiffViewer.classList.remove('hidden');
                container.appendChild(jsonDiffViewer);
            }
        }, 500);
    }

    async executeAPI(apiRegistryId, apiData) {
        try {
            const apiRegistry = await this._commonService
                .getDataById(MODELS_CONSTANTS.API_REGISTRIES, apiRegistryId)
                .toPromise()
                .then((res) => res.data)
                .catch((err) => console.error(err));
    
            const response = await this._commonService
                .executeApiMethod(apiRegistry, apiData)
                .catch((err) => console.error(err));
    
            return response?.data || null;
        } catch (error) {
            return null;
        }
    }

    async getExcelJSON(file_url) {
        const requestBody = {
            file_url,
            module_code: MODELS_CONSTANTS.AI_EXCEL_UPLOAD
        }
        const response: any = await this._commonService.getJsonFromExcel(requestBody).toPromise().catch(err => console.error(err));

        let json;
        if(response?.data) {
            try {
                json = JSON.parse(response?.data);
            } catch (error) {
                json = response?.data;
            }
        }

        return json || [];
    }

    async changeForm() {
        this.formdata = await this.select_url_replace(this.form_details.form_data, true);
        this.refreshForm.emit({
            form: this.formdata
        });
    }

    async checkFormChanges() {
        if(this.form_read_only()) {
            return false;
        } else {
            const currentFormData = await this.getCurrentFormData();
            const isChanges: boolean = !isEqual(this.initialFormData, currentFormData);
            
            try {
                if (isChanges) {
                    const oldValue = omitBy(this.initialFormData, (value, key) =>
                        isEqual(value, currentFormData[key])
                    );

                    const newValue = omitBy(currentFormData, (value, key) =>
                        isEqual(value, this.initialFormData?.[key])
                    );

                    const differences = { oldValue, newValue };
                    console.log('Differences between Old and New values', { differences });
                }
            } catch (error) {
                console.error(error);
            }

            return isChanges;
        }
    }

    async render_formSubmit(event: any, is_taks_create?: boolean, notifyVendor?: any, isReadOnly?: boolean) {
        const saveForm = async () => {
            if(isReadOnly) this.form_read_only.set(true);
            this.changeForm();
            let data = cloneDeep(event.data);
            const isMultipleFiles = data?.multiple_files && true;
            if(isMultipleFiles) {
                data = data?.multiple_files;
                const currentFileIds = data?.filter((e) => e?._id)?.map((e) => e?._id);
                const previousFileIds = this.submissiondata?.data?.all_data?.map((e) => e?._id);
                const fileToDeleteIds = previousFileIds?.filter((e) => !currentFileIds?.includes(e));
                if(fileToDeleteIds?.length){
                    await this.delete(fileToDeleteIds);
                }
            } else {
                delete data?.all_data;
                data = [{...data, ...data?.form_params}];
            };
            let response: any;
            for(let [index, form_data] of data.entries()) {
                // let form_data = cloneDeep(event.data);
                let isAdd = !form_data?._id;
                delete form_data.save;
                delete form_data.submit;
                delete form_data.createdAt;
                delete form_data.updatedAt;
                delete form_data.created_on;
                delete form_data.updated_on;
                delete form_data.created_by;
                delete form_data.updated_by;
                delete form_data.tenant_details;
                delete form_data.all_data;
                
                Object.keys(form_data).map(key => {
                    let { type, components } = this.find_key_to_type(this.formdata.components, key);
                    let key_value: any = form_data[key];
                    if (type == "file") {
                        form_data[key] =
                            key_value &&
                            key_value[0] &&
                            (key_value[0]['_id'] || key_value[0].data['_id'])
                                ? key_value[0]['_id'] || key_value[0].data['_id']
                                : null;
                    }
                    if (type == "datetime") {
                        if(key_value){
                            form_data[key] = moment(key_value).format("YYYY-MM-DD hh:mm")
                        }
                    }
                    if(type == 'datagrid') {
                        for(let doc of form_data[key]) {
                            Object.keys(doc).map((key) => {
                                if(components && components?.length) {
                                    const { type } = this.find_key_to_type(components, key);
                                    if(type == "file") {
                                        let doc_key_value: any = doc[key];
                                        
                                        doc[key] =
                                        doc_key_value &&
                                        doc_key_value[0] &&
                                        (doc_key_value[0]['_id'] || doc_key_value[0].data['_id'])
                                            ? doc_key_value[0]['_id'] || doc_key_value[0].data['_id']
                                            : null;
                                    }
                                }
                            })
                        }
                    }
                });
                if (this.form_details.parent_id && this.data.parent_row_data && this.data.parent_row_data.vs_code) {
                    form_data[this.form_details.parent_id] = this.data.parent_row_data.vs_code;
                } else if (this.form_details.parent_id && this.data.parent_row_data && this.data.parent_row_data._id) {
                    form_data[this.form_details.parent_id] = this.data.parent_row_data._id;
                }
        
                if (this.form_module_code === 'form_footer') {
                    if (form_data?._id) {
                        this.update(form_data)
                    } else {
                        form_data['form_id'] = this.data.id;
                        this.add(form_data);
                    }
                } else {
                    if (this.data.params && this.data.params.length && !this.data.is_from_board) {
                        let params = this.params_value_replace(this.data.params, this.data.row_data, this.data.parent_row_data);
                        if (params && params.length) {
                            params.forEach((p: any) => {
                                form_data[p.key] = p.value;
                            });
                        }
                    } else if (this.data.id && !this.data.is_from_board && !isMultipleFiles) {
                        if(!(this.form_details.parent_id && form_data[this.form_details.parent_id])) {
                            if (this.form_module_code_category === "CORE") {
                                form_data['_id'] = this.data.id;
                            } else if (this.form_module_code_category === "VRM") {
                                form_data['id'] = this.data.id;
                            }
                        }
                    }
    
                    if (isAdd) {
                        response = await this.add(form_data, is_taks_create, notifyVendor);
                    } else {
                        response = await this.update(form_data, is_taks_create);
                    }
                }
    
                if(index === data?.length - 1 && response?.status === 200) {
                    this.initialFormData = response.data;
                   if(this.data?.close_drawer){
                       this.data?.close_drawer();
                   } else if(this.data?.close_dialog) {
                       this.close(false, response?.data?._id, response?.data);
                   }
                }
            }
        }

        const hasChanges = await this.checkFormChanges();

        if(!hasChanges) {
            const dialogRef = this.confirmationService.open({
                title: 'No Changes to Save',
                message: 'There are no changes to save.',
                actions: {
                    confirm: {
                        show: true,
                        label: 'OK',
                        color: 'primary'
                    },
                    cancel: {
                        show: false
                    }
                }
            });
    
            dialogRef.afterClosed().subscribe(() => {
                console.log('Dialog closed');
            });
        } else {
            await saveForm();
        }
    }

    async add(form_data, is_taks_create = false, notifyVendor = null) {
        if (this.form_module_doc_no_flag) {
            if (this.form_module_code_url.endsWith('/')) {
                this.form_module_code_url +=
                    SHARED_CONSTANTS.CREATE_WITH_DOC_NO_ENDPOINT;
            } else {
                this.form_module_code_url +=
                    '/' + SHARED_CONSTANTS.CREATE_WITH_DOC_NO_ENDPOINT;
            }
        }
        const response = await this._commonService.callAPI(
            this.form_module_code_url,
            'post',
            this.form_module_code,
            form_data,
            {}
        ).toPromise()
            .catch((err) => {
                console.error(err);
                this.snackbar.error(err?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            });
            if (!is_taks_create) {
                this.addSaveSubmit(form_data.status); 
            }

        try {
            if (response?.status === 200) {
                this.submissiondata = { data: response.data };
                this.formFooterData.footerData = this.submissiondata.data;
                this.formFooterData = { ...this.formFooterData };
                this.snackbar.success(`Form added successfully`);

                if (this.data?.handle_clock) {
                    this.data?.handle_clock();
                }

                const body = {
                    form_id: this.data.form_id,
                    ref_id: response?.data?._id,
                    action: 'create',
                    remarks: 'Form Data Created',
                    where: "",
                    date_time: new Date(),
                    done_by: response?.data?.created_by || this.authService?.userInfo?._id
                };

                this._commonService.saveRecord(MODELS_CONSTANTS.FORM_ACTIVITY_TRACKER, body)
                    .subscribe({
                        next: () => { },
                        error: (saveError) => {
                            console.error(saveError);
                            this.snackbar.error(saveError?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                        }
                    });
                    this._notificationsService.add(body, this.data);

                if (is_taks_create) {
                    this.call_create_task(form_data);
                }
                if (this.data.close_dialog) {
                    // this.data?.close_dialog(form_data);
                }
                
                if(notifyVendor) {
                    notifyVendor(response?.data);
                    this.submissiondata.data = response?.data;
                    this.submissiondata = { ...this.submissiondata };
                } else {
                    if (this.data.isReturnAddData) {
                        this.data?.close_dialog(response.data, false);
                    }
                }
            }
        } catch (error) {
            console.error(error);
            this.snackbar.error(error?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
        }
        return response;
    }

    async update(form_data, is_taks_create = false) {
        const response: IResponse<any> = await this._commonService.callAPI(
            this.form_module_code_url,
            'put',
            this.form_module_code,
            form_data,
            {}
        ).toPromise()
            .catch((err) => {
                console.error(err);
                this.snackbar.error(err?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            });
            if (!is_taks_create) {
                this.addSaveSubmit(form_data.status); 
            }

        try {
            if (response?.status === 200) {
                this.snackbar.success(`Form updated successfully`);

                const body = {
                    form_id: this.data.form_id,
                    ref_id: response?.data?._id,
                    action: 'update',
                    remarks: 'Form Data Updated',
                    where: "",
                    date_time: new Date(),
                    done_by: response?.data?.updated_by || this.authService?.userInfo?._id
                };

                this._commonService.saveRecord(MODELS_CONSTANTS.FORM_ACTIVITY_TRACKER, body)
                    .subscribe({
                        next: () => { },
                        error: (saveError) => {
                            console.error(saveError);
                            this.snackbar.error(saveError?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                        }
                    });
                    this._notificationsService.add(body, this.data);

                if (is_taks_create) {
                    this.call_create_task(form_data);
                }
                if (this.data.close_dialog) {
                    //this.data?.close_dialog(form_data);
                }
            }
        } catch (error) {
            console.error(error);
            this.snackbar.error(error?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
        }
        return response;
    }

    async delete(ids: string | string[]){
        let response: any;
        if(typeof ids === 'string'){
            response = await this._commonService.callAPI(
                this.form_module_code_url,
                'delete',
                this.form_module_code,
                {},
                {_id: ids}
            ).toPromise(); 
        } else {
            for(let id of ids){
                if(id) {
                    response = await this._commonService.callAPI(
                        this.form_module_code_url,
                        'delete',
                        this.form_module_code,
                        {},
                        {_id: id}
                    ).toPromise(); 
                }
            }
        }
    }

    approve() {
        this._router.navigate(['/admin/formdata-table', this.form_id]);
    }

    reject() {
        this._router.navigate(['/admin/formdata-table', this.id]);
    }

    close(confirmation = true, id, data) {
        if(this.data.close_dialog) {
            this.data.close_dialog(false, confirmation, false, id, data);
        }
        if (this.data?.handle_clock) {
            this.data.handle_clock = null;
        }
        this.actionComponent = null;
    }

    openComponent(action) {
        if(action?.action_type === 'required-fields') {
            this.calculateRequiredFields();
            this.dialog.open(this.form_required_fields, {
                autoFocus: false,
                height: '60vh',
                width: '50vw',
            })
        } else {
            this.actionComponent = COMPONENT_REFERENCE?.[action?.action_type];
            const inputs = {};
            if (
                action?.action_type === 'api' ||
                action?.action_type === 'redirection'
            ) {
                return;
            } else if (action?.action_type === 'list_view') {
                inputs['listId'] = action?.list_view;
                inputs['row_data'] = this.data?.row_data || {};
                inputs['parent_row_data'] = this.data?.row_data || {};
            } else if (action?.action_type === 'form_render') {
                inputs['data'] = {
                    id: this.data?.row_data?._id || this.data?.row_data?.id,
                    form_id: action?.form_render,
                    title: action?.name || action?.tooltip,
                    row_data: this.data?.row_data || {},
                    close_drawer: () => { this.matDrawer?.close() },
                    action
                };
            } else {
                if (
                    this.actionComponent?.inputs &&
                    'data' in this.actionComponent?.inputs
                ) {
                    inputs['data'] = {
                        id: this.data?.row_data?._id || this.data?.row_data?.id,
                        reference_type_id: action?.ref_id,
                        form_id: this.data?.form_id,
                        title: this.data?.title,
                        row_data: this.data?.row_data || {},
                        reference_id:
                            this.data?.row_data?._id || this.data?.row_data?.id,
                        reference_type: action?.ref_type
                    };
                    if (action?.configurations) {
                        inputs['data'] = {
                            ...inputs['data'],
                            ...action?.configurations,
                        };
                    }
                } else if (
                    this.actionComponent?.inputs &&
                    'params' in this.actionComponent?.inputs
                ) {
                    inputs['params'] = this.formFooterData.footerPermissions;
                } else if (
                    this.actionComponent?.inputs &&
                    'row_data' in this.actionComponent?.inputs
                ) {
                    if(action?.row_data) {
                        inputs['row_data'] = action?.row_data;
                    } else {
                        inputs['row_data'] = this.data?.row_data;
                    }
                } else if (
                    this.actionComponent?.inputs &&
                    'form_data' in this.actionComponent?.inputs
                ) {
                    inputs['form_data'] = {
                        row_data: this.submissiondata.data,
                        form_id: this.data?.form_id,
                    };
                }
            }
    
            this.drawerWidth = action?.drawer_width || '100%';
            this.classType = action?.class_type || 'Icon-Size-md';
            this.actionComponent.inputs = inputs;
    
            if (this.actionComponent) {
                this.matDrawer.open();
            }
        }
    }

    file_to_key(file) {
        let key = "vortex/";
        if (file.tenant_id) key += file.tenant_id;
        if (file.category) key = `${key}/${file.category}`;
        if (file.sub_category) key = `${key}/${file.sub_category}`;
        key = `${key}/${file.key}`;
        return key;
    }

    async call_create_task(form_data) {
        let task_name_fiels = this.find_key_to_tags(this.formdata.components, 'task_name');
        let task_description_fiels = this.find_key_to_tags(this.formdata.components, 'task_description');
        let payload = {
            board_id: this.form_details.board_id,
            ref_type: "form_render",
            "ref_type_id": this.data.form_id,
            "ref_id": form_data._id,
            "title": task_name_fiels && form_data[task_name_fiels] ? form_data[task_name_fiels] : this.form_details.form_name,
            "description": task_description_fiels && form_data[task_description_fiels] ? form_data[task_description_fiels].toString() : "",
        };
        const form = await this._commonService
            .getDataByFields(MODELS_CONSTANTS.BOARD_TASK_FORM, {
                ref_type: 'form_render',
                ref_type_id: this.data.form_id,
                ref_id: form_data._id,
            })
            .toPromise()
            .then((res) => res.data[0])
            .catch((err) => console.error(err));
        if(form && form?.task_id) {
            const task = await this._commonService
                .getDataById(MODELS_CONSTANTS.BOARD_TASK, form?.task_id)
                .toPromise()
                .then((res) => res.data)
                .catch((err) => console.error(err));

            if(task) { 
                await this._commonService.saveRecord(MODELS_CONSTANTS.BOARD_TASK, { _id: task?._id, status: 1 }).toPromise();
                return;
            } 
        }
        await this._commonService.create_task(payload).toPromise();
    }

    params_value_replace(params: any = [], row_data: any = {}, parent_row_data: any = {}) {
        return params.map((item: any) => {
            let value = item.value;

            value = value.replace(/{{row_data\.(.*?)}}/g, (match, p1) => row_data[p1] || match);
            value = value.replace(/{{parent_row_data\.(.*?)}}/g, (match, p1) => parent_row_data[p1] || match);

            if (value.includes('{{')) {
                return null;
            }

            return {
                key: item.key,
                value: value
            };
        }).filter(item => item !== null);
    }

    find_file_components(components: any[]): any[] {
        let fileComponents = [];
        components.forEach(component => {
            if (component.type === 'file') {
                fileComponents.push(component);
            } else if (component.columns) {
                fileComponents = fileComponents.concat(this.find_file_components(component.columns));
            } else if (component.tabs) {
                component.tabs.forEach(tab => {
                    tab.components.forEach(tabComponent => {
                        if (tabComponent.columns) {
                            fileComponents = fileComponents.concat(this.find_file_components(tabComponent.columns));
                        } else if (tabComponent.type === 'file') {
                            fileComponents.push(tabComponent);
                        }
                    });
                });
            } else if (component.fieldset) {
                fileComponents = fileComponents.concat(this.find_file_components(component.fieldset));
            } else if (component.type === 'table') {
                component.rows.forEach(row => {
                    row.forEach(tableComponent => {
                        fileComponents = fileComponents.concat(this.find_file_components([tableComponent]));
                    });
                });
            } else if (component.type === 'panel') {
                fileComponents = fileComponents.concat(this.find_file_components(component.components));
            } else if (component.components) {
                fileComponents = fileComponents.concat(this.find_file_components(component.components));
            }
        });
        return fileComponents;
    }

    find_grid_components(components: any[]): any[] {
        const gridComponents = [];
        for(const component of components) {
            if(component.type === 'datagrid') {
                gridComponents.push(component);
            }
        }
        return gridComponents;
    }

    find_key_to_tags(components: any[], tag: any): string | null {
        for (const component of components) {
            // Check if component has tags and tags is an array
            if (component.tags && Array.isArray(component.tags)) {
                if (component.tags.includes(tag)) {
                    return component.key;
                }
            }

            // Check if component has columns
            if (component.columns) {
                const key = this.find_key_to_tags(component.columns, tag);
                if (key) {
                    return key;
                }
            }

            // Check if component has tabs
            else if (component.tabs) {
                for (const tab of component.tabs) {
                    const key = this.find_key_to_tags(tab.components, tag);
                    if (key) {
                        return key;
                    }
                }
            }

            // Check if component has a fieldset
            else if (component.fieldset) {
                const key = this.find_key_to_tags(component.fieldset, tag);
                if (key) {
                    return key;
                }
            }

            // Check if component is a table
            else if (component.type === 'table') {
                for (const row of component.rows) {
                    for (const tableComponent of row) {
                        const key = this.find_key_to_tags([tableComponent], tag);
                        if (key) {
                            return key;
                        }
                    }
                }
            }

            // Check if component is a panel
            else if (component.type === 'panel') {
                const key = this.find_key_to_tags(component.components, tag);
                if (key) {
                    return key;
                }
            }

            // Check if component has nested components
            else if (component.components) {
                const key = this.find_key_to_tags(component.components, tag);
                if (key) {
                    return key;
                }
            }
        }
        return null; // Key not found
    }

    find_key_to_type(components: any[], key: any): { type: string, components?: any } | null {
        if(components && components?.length) {
            for (const component of components) {
                if (component.key === key) {
                    const obj = { type: component.type };
                    if(component.type === 'datagrid') {
                        obj['components'] = component.components;
                    }
                    return obj;
                } else if (component.columns) {
                    const { type } = this.find_key_to_type(component.columns, key);
                    if (type) {
                        return { type };
                    }
                } else if (component.tabs) {
                    for (const tab of component.tabs) {
                        for (const tabComponent of tab.components) {
                            if (tabComponent.key === key) {
                                return { type: tabComponent.type };
                            }
                        }
                    }
                } else if (component.fieldset) {
                    const { type } = this.find_key_to_type(component.fieldset, key);
                    if (type) {
                        return { type };
                    }
                } else if (component.type === 'table') {
                    for (const row of component.rows) {
                        for (const tableComponent of row) {
                            if (tableComponent.key === key) {
                                return { type: tableComponent.type };
                            }
                        }
                    }
                } else if (component.type === 'panel') {
                    const { type } = this.find_key_to_type(component.components, key);
                    if (type) {
                        return { type };
                    }
                } else if (component.components) {
                    const { type } = this.find_key_to_type(component.components, key);
                    if (type) {
                        return { type };
                    }
                }
            }
        }
        return { type: null }; // Key not found
    }

    notifyVendor = async (vendorInfo: any) => {
        vendorInfo = cloneDeep(vendorInfo);
        if (vendorInfo.email) {
            let userInfo: any = {};
            vendorInfo.redirect_url = '/home';
            let response: any = await this._commonService
                .vendor(vendorInfo)
                .toPromise()
                .catch((err) => {
                    console.error(err)
                });
            if (response?.status == 200 && response?.data) {
                userInfo = response?.data;
            }
            if (userInfo._id) {
                userInfo.url = environment.appURL + 'sign-in?params=' + userInfo?.params;
                const result: any = await this._commonService.mailUser(
                    userInfo._id,
                    TEMPLATES_CONSTANTS.MAIL_VENDOR,
                    { userInfo: userInfo, vendorInfo: vendorInfo }
                ).toPromise();
                if (result?.status === 200) {
                    this.snackbar.success('Notification mail sent successfully');
                }
            }
        }
    }

    zoomPdfPage(scale: number) {
        this.pageZoom.update((zoom) => zoom + scale);
    }

    resetPdfZoom(){
        this.pageZoom.set(1);
    }

    afterPdfLoaded(pdf: PDFDocumentProxy) {
        this.totalPdfPages = pdf.numPages;
    }

    onPdfProgress(progressData: PDFProgressData) {
        this.progressValue = (progressData.loaded / progressData.total) * 100;
        if (progressData.loaded === progressData.total) {
            setTimeout(() => {
                this.showPdfLoader.set(false);
                this.progressValue = 0;
            }, 1500);
        } else {
            this.showPdfLoader.set(true);
        }
    }

    changePdfPage(count: number) {
        this.currentPdfPage += count;
    }

    jumpToPage(pageNum: number){
        this.currentPdfPage = pageNum;
    }

    // Function to download JSON
    downloadJson(jsonData, title = 'jsondata') {
        const jsonString = JSON.stringify(jsonData, null, 2);

        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
    }
    checkRequiredFields(): boolean {
        if (this.formdata) {
          for (const controlName in this.formdata.controls) {
            const control = this.formdata.get(controlName);
            if (control?.hasError('required') && control.touched) {
              return false; // If any required field is invalid, return false
            }
          }
        }
        return true; // All required fields are valid
      }
    
    async getCurrentFormData() {
        this.show_loader();

        let formData = cloneDeep(this.submissiondata.data);

        if(!this.moduleSchemaKeys || !this.moduleSchemaKeys?.length) {
            const moduleData = await this._commonService
                .getDataByFields(
                    MODELS_CONSTANTS.MODULES,
                    {
                        module_id: this.form_module_id
                    }
                )
                .toPromise()
                .then((res) => res?.data[0])
                .catch((err) => console.error(err));
    
            let moduleSchema = moduleData?.module_schema;
    
            if(moduleSchema) {
                moduleSchema = moduleSchema.split('\n');
                moduleSchema.forEach((line) => {
                    const matches = line.match(/^\s*([\w-]+):\s*{([^}]*)},?\s*$/);
                    if(matches && matches?.length === 3) {
                        const key = matches[1];
                        this.moduleSchemaKeys?.push(key);
                    }
    
                })
            }
        }

        for(let key in formData) {
            if(!this.moduleSchemaKeys?.includes(key)) {
                delete formData[key];
            }
        }

        this.hide_loader();

        return formData;
    }

    isEmpty(value): boolean {
        if (typeof value === 'object' && value !== null) {
            return Object.values(value).every(this.isEmpty);
        }
        return value === null || value === undefined || value === '' || value === 0;
    }

    hasFormChanges(): boolean {
        return this.formdata.dirty || this.formdata.touched;  // This checks if the form has unsaved changes
    } 

    receiveData(event) {
        this.dataEvent.emit(event);
        if(this.data.dataEvent) {
            this.data.dataEvent(event);
        }
    }

    getRequiredFields(components = [], isHighlight = false) {
        const allRequiredFields = [];
        const requiredFields = [];
      
        components.forEach(component => {
          // If the component is required, add its key
          if (component.validate && component.validate.required) {
            allRequiredFields.push(component.label);
            if(!this.submissiondata.data[component.key] && !component?.hidden && !component?.defaultValue) {
                requiredFields.push(component);

                if(isHighlight && !component?.customClass?.includes('field-highlight')) {
                    const { show, when, eq } = component?.conditional;
                    if((show == true || show == false) && when && eq) {
                        const fieldData = this.submissiondata.data[when];
                        if(show) {
                            if(fieldData == eq) {
                                component.customClass += ' field-highlight';
                            }
                        } else {
                            if(fieldData != eq) {
                                component.customClass += ' field-highlight';
                            }
                        }
                    } else {
                        component.customClass += ' field-highlight';
                    }
                }
            }
          }
      
          // If the component has nested components, recursively check those
          if (component.components && component.components.length > 0) {
            const { allRequiredFields: allReqFields, requiredFields: reqFields } = this.getRequiredFields(component.components, isHighlight);
            allRequiredFields.push(...allReqFields);
            if(!this.submissiondata.data[component.key] && !component?.hidden && !component?.defaultValue) {
                requiredFields.push(...reqFields);
            }
          }
      
          // Check for nested components in "datagrid" or similar structures
          if (component.type === 'columns' && component.columns) {
            const { allRequiredFields: allReqFields, requiredFields: reqFields } = this.getRequiredFields(component.columns, isHighlight);
            allRequiredFields.push(...allReqFields);
            if(!this.submissiondata.data[component.key] && !component?.hidden && !component?.defaultValue) {
                requiredFields.push(...reqFields);
            }
          }
        });
      
        return { allRequiredFields, requiredFields };
    }

    afterFormLoad(formData) {
        if(this.form_read_only()) {
            const pdfViewer = document.getElementById('form-pdf-viewer');
            if(pdfViewer) {
                this.displayPdf(this.submissiondata);
            }
        }
        this.hide_loader();
    }

    calculateRequiredFields() {
        let isHighlight = false;

        if(this.form_details?.is_approval) {
            isHighlight = true;
        }

        const data = this.getRequiredFields(this.formdata?.components, isHighlight);
        const { requiredFields, allRequiredFields } = data;

        const requiredFieldsNames = [];

        if(requiredFields?.length) {
            for(let field of requiredFields) {
                const { show, when, eq } = field?.conditional;
                if((show == true || show == false) && when && eq) {
                    const fieldData = this.submissiondata.data[when];
                    if(show) {
                        if(fieldData == eq) {
                            requiredFieldsNames?.push(field?.label);
                        } else {
                            const label = field?.label;
                            allRequiredFields?.splice(allRequiredFields?.indexOf(label), 1);
                        }
                    } else {
                        if(fieldData != eq) {
                            requiredFieldsNames?.push(field?.label)
                        } else {
                            const label = field?.label;
                            allRequiredFields?.splice(allRequiredFields?.indexOf(label), 1);
                        }
                    }
                } else {
                    requiredFieldsNames?.push(field?.label);
                }
            }
        };

        this.allRequiredFields = allRequiredFields;
        this.requiredFields = requiredFieldsNames;
    }

    show_loader() {
        this.loaderCount += 1;
        this.showLoader.set(true);
    }

    hide_loader() {
        setTimeout(() => {
            if(this.loaderCount > 0) {
                this.loaderCount -= 1;
            }

            if(this.loaderCount == 0) {
                this.showLoader.set(false);
            }
        }, 1000);
    }

    setFormStatus(data, asBoolean = false) {
        try {
            if(data) {
                if (asBoolean) {
                    if (data?.status !== undefined && data?.status !== null) {
                        if (data?.status == 0) {
                            data.status = true;
                        } else if (data.status == 1) {
                            data.status = false;
                        }
                    } else {
                        data.status = true;
                    }
                } else {
                    if (
                        this.form_details.is_approval &&
                        this.form_details.board_id
                    ) {
                        if (!data?.status) {
                            data.status = 0;
                        }
                    } else {
                        if (data?.status == undefined || data?.status == null) {
                            if (this.form_details?.client) {
                                data.status = 0;
                            } else {
                                data.status = 1;
                            }
                        } else {
                            if (data.status == true) {
                                data.status = 0;
                            } else {
                                data.status = 1;
                            }
                        }
                    }
                }
            }

            return data;
        } catch (error) {
            return data;
        }
    }

    closeDrawer() {
        this.pdfSrc.set(null);
        this.actionComponent = null;
        this.matDrawer.close();
    }

    downloadPdf() {
        const url = this.pdfSrc();
        const fileName = url.split('/').pop() || 'download.pdf';
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }
}
